<template>
  <div class="procurementContractDetailsPageBox page-info-content">
    <Details-page :list-data="detailObj.goodsDetailList" :item-data="itemData" :table-title="'采购货物明细'" :detail-obj="detailObj" :details-item-arr="detailsItemArr" />
  </div>
</template>

<script>
import DetailsPage from '@/components/DetailsPage.vue'
import { agentPurchaseContractFundsDetail } from '@/api/generationMining'
export default {
  components: { DetailsPage },
  data() {
    return {
      detailObj: {
        goodsDetailList: []
      },
      itemData: [
        { label: '品名', prop: 'goodsName' },
        { label: '材质', prop: 'goodsMaterials' },
        { label: '规格', prop: 'goodsSpecs' },
        { label: '产地', prop: 'goodsProduct' },
        { label: '件重(吨)', prop: 'goodsWeights' },
        // { label: '采购数量', prop: 'purchaseQuantity' },
        { label: '采购数量(吨)', prop: 'purchaseWeight' },
        { label: '采购单价(元/吨)', prop: 'purchasePrice' },
        { label: '价格合计(元)', prop: 'totalPrice' }
      ],
      detailsItemArr: [
        { label: '经销商', value: 'custCmpName', width: 200 },
        { label: '代采商', value: 'fundsCmpName', width: 200 },
        { label: '合作企业', value: 'supplierCmpName', width: 200 },
        { label: '货款金额', value: 'totalPayment', rightWords: '元' },
        { label: '仓库', value: 'deliveryWarehouseName' },
        { label: '交货方式', value: 'deliveryType', child: [{ dictName: '卖家配送', dictId: 1 }, { dictName: '买家自提', dictId: 2 }, { dictName: '直发', dictId: 3 }] },
        { label: '交货详细地址', value: 'deliveryWarehouseAddr' },
        { label: '关联销售合同', value: 'custContractNo' },
        { label: '采购协议', value: 'purchaseFileName', link: 'purchaseFileUrl' },
        { label: '结算周期', value: 'settleDate', leftWords: '每月', rightWords: '号' },
        { label: '备注', value: 'remark' }
      ],
      breadcrumbItem: [{ path: '/', name: '首页' }, { path: '/procurementContract/index', name: '采购合同管理' }, { path: '', name: '采购合同详情' }]
    }
  },
  created() {
    if (this.$route.query.id) {
      this.getDetails()
    }
  },
  methods: {
    getDetails() {
      agentPurchaseContractFundsDetail(this.$route.query.id, res => {
        this.detailObj = res.data
        if (res.data.goodsDetailList) {
          res.data.goodsDetailList.forEach(item => {
            item.goodsSpecs = item.goodsSpecs === '-1' ? '混合规格' : item.goodsSpecs
            item.goodsProduct = item.goodsProduct === '-1' ? '不限' : item.goodsProduct
            item.goodsWeights = item.goodsWeights === -1 ? '不限' : item.goodsWeights
          })
        }
        this.detailObj.goodsDetailList = res.data.goodsDetailList || []
      })
    }
  }
}
</script>
<style lang="scss">
.procurementContractDetailsPageBox{
  .cancelButton{
    text-align: center;
    margin-top: 18px;
  }
}
</style>
